import React, {useContext, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from '../../components/layout';
import Result from '../../components/result';
import loop from '../../img/icons/search-icon.svg';
import {navigate} from 'gatsby';
import icon1 from '../../img/icons/icon-1.svg';
import icon2 from '../../img/icons/icon-2.svg';
import icon3 from '../../img/icons/icon-3.svg';
import icon4 from '../../img/icons/icon-4.svg';
import {playIcon} from '../../img/svg-icons';
import Steps from '../../components/steps';
import BottomSection from '../../components/bottom-section';
import {UserContext} from '../../context/user-context';

const SocialSpy = () => {
  const {t, i18n} = useTranslation();
  const {user} = useContext(UserContext);
  const [usernameValue, setUsernameValue] = useState('');

  const handleCaptureOnEnter = e => {
    if (e.key === 'Enter') {
      handleGo();
    }
  };

  const handleNewScanRequest = () => {
    const navLink = usernameValue.trim();
    navigate(`/${navLink.toLocaleLowerCase()}/`);
  };

  const handleGo = () => {
    const navLink = usernameValue.trim();
    navigate(`${i18n.language === 'ar' ? '/ar' : ''}/${navLink.toLocaleLowerCase()}`);
  };

  const handleChange = e => {
    setUsernameValue(e.target.value);
  };

  return (
    <Layout title={t('social-spy-page-title')} description={t('social-spy-page-description')}>
      <div className="search-wrapper position-relative">
        <img
          src={icon4}
          style={{width: '45px', height: 'auto', position: 'absolute', top: '25%', left: '6%'}}
          className="d-none d-md-block"
        />
        <img
          src={icon1}
          style={{width: '25px', height: 'auto', position: 'absolute', top: '0%', left: '12%'}}
          className="d-none d-md-block"
        />
        <img
          src={icon1}
          style={{width: '25px', height: 'auto', position: 'absolute', top: '-25%', left: '35%'}}
          className="d-none d-md-block"
        />
        <img
          src={icon3}
          style={{width: '70px', height: 'auto', position: 'absolute', top: '-20%', left: '18%'}}
          className="d-none d-md-block"
        />
        <img src={icon3} style={{width: '45px', height: 'auto', position: 'absolute', top: '-15%', left: '5%'}} />
        <img
          src={icon3}
          style={{width: '70px', height: 'auto', position: 'absolute', top: '15%', left: '18%'}}
          className="d-none d-smdblock"
        />

        <img src={icon1} style={{width: '35px', height: 'auto', position: 'absolute', top: '-20%', left: '90%'}} />
        <img src={icon2} style={{width: '35px', height: 'auto', position: 'absolute', top: '-30%', left: '65%'}} />
        <img
          src={icon2}
          style={{width: '40px', height: 'auto', position: 'absolute', top: '30%', left: '75%'}}
          className="d-none d-md-block"
        />
        <img
          src={icon4}
          style={{width: '45px', height: 'auto', position: 'absolute', top: '25%', left: '88%'}}
          className="d-none d-md-block"
        />
        <img src={icon3} style={{width: '70px', height: 'auto', position: 'absolute', top: '5%', left: '80%'}} />

        <h1 className="page-title">{t('social-spy-title')}</h1>
        <h2 className="page-subtitle mb-4">{t('social-spy-description')}</h2>
        <div className={`form-container field is-grouped ${i18n.language === 'ar' ? 'left-align' : ''}`}>
          <input
            className={`input is-rounded inputBar ${i18n.language === 'ar' ? 'typing-rl' : ''}`}
            type="text"
            placeholder={t('nickname-or-username')}
            value={usernameValue}
            onChange={handleChange}
            onKeyPress={handleCaptureOnEnter}
            maxLength={320}
          />
          <img
            src={loop}
            style={{
              width: '25px',
              height: '25px',
              position: 'absolute',
              top: '15px',
              left: i18n.language === 'ar' ? '' : '15px',
              right: i18n.language === 'ar' ? '15px' : '',
            }}
          />
          <div className="buttons is-block btn_capture">
            <button className={`button is-primary is-outlined is-medium is-rounded inputBarButton`} onClick={handleGo}>
              {playIcon}
            </button>
          </div>
        </div>
      </div>
      <Steps
        text1={`${t('social-spy-section-1')}`}
        text2={`${t('social-spy-section-2')}`}
        text3={
          <>
            {t('social-spy-section-3')} <span style={{color: '#fff', fontWeight: 700}}> {t('400')} </span> {t('Step-4')}
          </>
        }
      />
      {/* {(user && user.package_data && user.package_data.name && user.package_data.name.toLowerCase() === 'free') ||
      (user && !user.package_data) ? (
        <>
          <a href="https://ad.candidatechecker.io" target="_top" id="512104">
            <img src="//a.impactradius-go.com/display-ad/7452-512104" border="0" alt="" width="728" height="90" />
          </a>
          <img
            height="0"
            width="0"
            src="https://nordvpn.sjv.io/i/2559811/512104/7452"
            style={{position: 'absolute', visibility: 'hidden'}}
            border="0"
          />
        </>
      ) : null} */}
      <BottomSection
        title1={`${t('social-spy-section-article-1')}`}
        title2={`${t('social-spy-section-article-2')}`}
        text1={
          <>
            <p className={i18n.language === 'ar' ? 'text-right' : ''}>{t('social-spy-section-5')} </p>
            <p className={i18n.language === 'ar' ? 'text-right' : ''}>{t('social-spy-section-6')}</p>
          </>
        }
        text2={
          <>
            <p className={i18n.language === 'ar' ? 'text-right' : ''}>{t('social-spy-section-7')}</p>
            <p className={i18n.language === 'ar' ? 'text-right' : ''}>{t('social-spy-section-8')}</p>
          </>
        }
      />
    </Layout>
  );
};

export default SocialSpy;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
